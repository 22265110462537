<template>
  <div class="logout">
  Logout...
  </div>
</template>

<script>

export default {
    created(){
        window.console.log('Hace logout');
        this.$ls.remove('token');
        this.$ls.remove('driver_id');
        this.$ls.remove('signature');
        this.$ls.remove('driver_type');
        this.$ls.remove('driver_onesignal');
        this.$store.commit('logout');
        this.$router.push('/login');
    }
}
</script>
