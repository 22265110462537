<template>
  <div class="post-trip-inspection text-center py-3">
        <v-toolbar
          color="#E21A22"
          dark
        >
              <v-toolbar-title>Post Trip Inspection</v-toolbar-title>
        </v-toolbar>
        <v-list v-if="!inspections.length">
          <template>
            <v-list-item-content>
              <div class="px-5">{{ message }}</div>
            </v-list-item-content>
          </template>
        </v-list>
        <v-list v-if="inspections.length" class="text-left">
            <v-list-group :value="false">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>Inspections</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item v-for="(item, index) in inspections"
                  :key="'it-' + index"
                >
                  <v-list-item-content>
                    <v-row>
                        <v-switch v-model="item.status" :label="item.inspection" class="ml-5"></v-switch>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-group :value="false">
                <template v-slot:activator>
                    <v-list-item-title>Defects</v-list-item-title>
                </template>
                <v-list-group no-action sub-group value="false" v-for="(item, index) in defects" :key="'sub-' + index">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.type }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item v-for="(i, ind) in item.defects"
                      :key="'ite-' + ind"
                    >
                      <v-list-item-content>
                        <v-row>
                            <v-switch v-model="i.status" :label="i.defect" class="ml-5"></v-switch>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list-group>
        </v-list>
        <v-row class="justify-content-center" v-if="inspections.length">
            <v-col>
                <v-textarea
                  label="Additional notes"
                  auto-grow
                  value=""
                  v-model="notes"
                ></v-textarea>
            </v-col>
        </v-row>
        <div v-if="inspections.length">
            <div class="caption">
                <strong>Please sign</strong>
            </div>
            <VueSignaturePad width="320px" height="300px" ref="signaturePad" class="mx-auto elevation-5 my-5" />
            <v-btn
              v-if="$store.getters.signature"
              color="success"
              @click="driverSignature"
              class="mb-5 mr-3"
            >
              Sign
            </v-btn> 
            <v-btn
              color="gray"
              @click="undo"
              class="mb-5"
            >
              Undo signature
            </v-btn>
        </div>
        <div v-if="inspections.length">
        <div class="caption" v-if="!numInspections && !numDefects">
            <strong>Disclaimer:</strong> I have performed the above inspection and found each item in proper working order. I haven't noted any defects.
        </div>
        <div class="caption" v-if="numInspections && !numDefects">
            <strong>Disclaimer:</strong> I have performed the above inspection and found <strong>{{ numInspections }}</strong> item(s) not in proper working order. I haven't noted any defects.
        </div>
        <div class="caption" v-if="numInspections && numDefects">
            <strong>Disclaimer:</strong> I have performed the above inspection and found <strong>{{ numInspections }}</strong> item(s) not in proper working order. I noted <strong>{{ numDefects }}</strong> defect(s).
        </div>
        <div class="caption" v-if="!numInspections && numDefects">
            <strong>Disclaimer:</strong> I have performed the above inspection and found each item in proper working order. I have noted <strong>{{ numDefects }}</strong> defect(s).
        </div>
        <v-btn
          color="primary my-5"
          @click="send"
        >
          SEND INSPECTION
        </v-btn>
    </div>
  </div>
</template>
<script>
export default {
    name: 'PostTripInspection',
    data: () => ({
        inspections: [],
        defects: [],
        signature: '',
        message: '',
        location: '',
        notes: '',
    }),
    computed: {
        numInspections: function(){
            return this.inspections.filter(item => item.status == false).length;
        },
        numDefects: function(){
            let defectos = 0;
            this.defects.forEach(function(item){
                console.log(item);
                defectos +=  item.defects.filter(it => it.status == true).length;
            });
            return defectos;
        }
    },
    components: {
    },
    methods: {
        driverSignature(){
          this.$refs.signaturePad.fromDataURL(this.$store.getters.signature);
        },
        undo() {
          this.$refs.signaturePad.undoSignature();
        },
        send() {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          if(isEmpty){
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": 'Please sign before submitting.'
              }
              this.$store.commit("msgMuestra", mensaje);
          } else {
              this.signature = data;
              var este = this;
              // Obtiene location
              este.$getLocation().then(coordinates => {
                  console.log(coordinates);
                  este.location = coordinates;
                  este.$http.post(este.$api + 'addInspection', {
                    driver_id: este.$store.getters.driver_id,
                    token: este.$store.getters.token,
                    type: 2,
                    inspections: este.inspections,
                    defects: este.defects,
                    signature: este.signature,
                    location: este.location,
                    notes: este.notes,
                  }).then(function(response){
                    console.log(response);
                    if(response.status == 200 && response.data.status == 'ok'){
                      console.log('Inspection added');
                      este.inspections = [];
                      este.defects = [];
                      este.signature = '';
                      este.message = response.data.msg;
                    } else {
                      var mensaje = {
                          "color": "red",
                          "timeout": 5000,
                          "message": response.data.msg
                      }
                      este.$store.commit("msgMuestra", mensaje);
                    }
                  }).catch(function(err){
                    var mensaje = {
                        "color": "red",
                        "timeout": 5000,
                        "message": "There was an error. Please try again."
                    }
                    este.$store.commit("msgMuestra", mensaje);
                    window.console.log(err);
                  });
              }).catch(function(err){
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "We couldn't get your location'. Please try again."
                }
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(err);
              });
          }
        },
        show: function(){
            var este = this;
            este.$http.post(este.$api + 'inspection', {
                driver_id: este.$store.getters.driver_id,
                token: este.$store.getters.token,
                type: 2
            }).then(function(response){
                console.log(response);
                if(response.status == 200 && response.data.status == 'ok'){
                      console.log('IMPRIME DATOS');
                      window.console.log(response.status);
                    if(!response.data.results){
                        este.message = response.data.msg;
                        este.inspections = [];
                    } else {
                        este.inspections = response.data.data.inspections;
                        este.defects = response.data.data.defects;
                    }
                } else {
                  este.inspections = [];
                  este.message = response.data.msg;
                  if('error' in response.data && response.data.error == 'logout'){
                      este.$router.push('/logout');
                  }
                }
            }).catch(function(err){
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "There was an error. Please try again."
                }
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(err);
          });
        }
    },
    mounted: function(){
        this.$store.commit('title', 'Post Trip Inspection');
        if([0, 6].includes(this.$moment().day())){
            this.inspections = [];
            this.message = 'There are no activities scheduled for today';
        } else {
            // Muestra inspección
            this.show();
        }
    }
}
</script>
