<template>
  <div class="signature text-center py-3">
        <v-toolbar
          color="#E21A22"
          dark
        >
              <v-toolbar-title>Signature</v-toolbar-title>
        </v-toolbar>
        <div v-if="$store.getters.signature">
            <v-img :src="$store.getters.signature" contain></v-img>
            <div class="caption">
                <strong>Disclaimer:</strong> I confirm that this is my signature and that it will be used to sign the confirmations within this application.
            </div>
        </div>
        <div v-else>
            <div class="caption mt-5">
                <strong>Please sign</strong>
            </div>
            <VueSignaturePad width="320px" height="300px" ref="signaturePad" class="mx-auto elevation-5 my-5" />
            <v-btn
              color="gray"
              @click="undo"
              class="mb-5"
            >
              Undo signature
            </v-btn>
            <div class="caption">
                <strong>Disclaimer:</strong> I confirm that this is my signature and that it will be used to sign the confirmations within this application.
            </div>
            <v-btn
              color="primary my-5"
              @click="send"
            >
              SAVE SIGNATURE
            </v-btn>
        </div>
  </div>
</template>
<script>
export default {
    name: 'Signature',
    data: () => ({
        signature: '',
    }),
    components: {
    },
    methods: {
        undo() {
          this.$refs.signaturePad.undoSignature();
        },
        send() {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          if(isEmpty){
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": 'Please sign before submitting.'
              }
              this.$store.commit("msgMuestra", mensaje);
          } else {
              this.signature = data;
              var este = this;
              // Obtiene location
              este.$http.post(este.$api + 'addSignature', {
                driver_id: este.$store.getters.driver_id,
                token: este.$store.getters.token,
                signature: este.signature,
              }).then(function(response){
                console.log(response);
                if(response.status == 200 && response.data.status == 'ok'){
                  console.log('Signature added');
                  este.message = response.data.msg;
                  este.$store.commit('signature', este.signature);
                  este.$ls.set('signature', este.signature);
                } else {
                  var mensaje = {
                      "color": "red",
                      "timeout": 5000,
                      "message": 'There was an error. Please try again.'
                  }
                  este.$store.commit("msgMuestra", mensaje);
                }
              }).catch(function(err){
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "There was an error. Please try again."
                }
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(err);
              });
          }
        },
    },
    mounted: function(){
        this.$store.commit('title', 'Signature');
    }
}
</script>
