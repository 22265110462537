<template>
  <div class="routes py-3">
    <div>
      <v-card class="mx-auto">
        <v-toolbar color="#E21A22" dark>
          <v-btn icon @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ dateFormat }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list three-line v-if="!routes.length">
          <template>
            <v-list-item-content>
              <v-list-item-title
                ><div class="px-5">{{ message }}</div></v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-list>
        <GmapMap
          ref="map"
          v-if="routes.length"
          :center="{ lat: 33.9190068, lng: -118.1200275 }"
          :position="google && new google.maps.LatLng(33.9190068, -118.1200275)"
          :zoom="14"
          :key="id"
          style="width: 100%; height: 400px;"
          class="mx-auto"
        >
        </GmapMap>
        <v-list three-line v-if="routes.length" class="py-0">
          <div v-for="(it, ind) in routes" :key="'rut-' + ind">
            <v-divider inset></v-divider>
            <v-subheader class="title white--text blue darken-1"
              >{{ it.route }} - {{ it.driver }}</v-subheader
            >
            <v-divider nset="inset"></v-divider>
            <template v-for="(item, index) in it.customers">
              <v-divider :key="'d-' + index"></v-divider>
              <v-list-item
                :key="'c-' + item.id"
                @click="muestraRuta(ind, index)"
                :class="'class' in item ? item.class : ''"
              >
                <v-list-item-avatar :key="'letter-' + index" color="#EA4335">
                  <span class="white--text headline text-center mx-auto">{{
                    letters[index]
                  }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="
                      '<strong>' +
                        item.businessName +
                        ' - ' +
                        item.accountNumber +
                        '</strong> (' +
                        item.route +
                        ')'
                    "
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.serviceAddress"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-html="item.contactPerson + ' - ' + item.phoneNumber"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar :key="'containers-' + index" color="green">
                  <span class="white--text headline text-center mx-auto">{{
                    item.numContainers
                  }}</span>
                </v-list-item-avatar>
              </v-list-item>
            </template>
          </div>
        </v-list>
      </v-card>
    </div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="#1B6BAA">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ route.businessName }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <GmapMap
            v-if="route"
            :center="center"
            :zoom="14"
            style="width: 100%; height: 400px;"
            class="mx-auto"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              @click="center = m.position"
            />
          </GmapMap>
          <v-card class="mb-10 elevation-5 mx-auto" outlined>
            <v-toolbar color="#E21A22" dark>
              <v-toolbar-title>Details</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div class="text-center mb-10">
                <p class="title">Swap Route</p>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  :color="activeRoute == rin ? 'primary' : 'grey'"
                  v-for="(r, rin) in routes"
                  :key="'rin-' + rin"
                  @click="changeLetter(rin)"
                >
                  <span class="title">{{ r.route }}</span>
                </v-btn>
              </div>
              <p><strong>Account number:</strong> {{ route.accountNumber }}</p>
              <p><strong>Route:</strong> {{ route.route }}</p>
              <p>
                <strong>Service address:</strong> {{ route.serviceAddress }}
              </p>
              <p>
                <strong>Days and hours of operation:</strong><br />
                <span
                  v-for="(dh, ind) in route.daysAndHoursOfOperation"
                  :key="'dh-' + ind"
                  >- {{ dh.name }}<br
                /></span>
              </p>
              <p>
                <strong>Containers at location:</strong><br />
                <span
                  v-for="(cn, ind) in route.containersAtLocation"
                  :key="'cn-' + ind"
                  >- {{ cn.container }}<br
                /></span>
              </p>
              <p><strong>E-mail:</strong> {{ route.email }}</p>
              <p><strong>Phone number:</strong> {{ route.phoneNumber }}</p>
              <p>
                <strong>Contact person at clinic:</strong>
                {{ route.contactPerson }}
              </p>
              <p><strong>Notes:</strong> {{ route.notes }}</p>
              <div class="mb-20"></div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<style scoped>
.onroute {
  background: #e3f2fd;
}
.checkin {
  background: #bbdefb;
}
.extrasnotes {
  background: #90caf9;
}
.completed {
  background: #42a5f5;
}
.closed {
  background: #f44336;
}
</style>
<script>
import { gmapApi } from "vue2-google-maps";
export default {
  data: () => ({
    id: 0,
    routes: [],
    mapsLoaded: false,
    route: "",
    activeRoute: "",
    routeIndex: "",
    center: "",
    points: [],
    markers: [],
    message: "",
    dialog: false,
    letters: [
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "X",
      "Y",
      "Z"
    ],
    dateFormat: "",
    date: "",
    location: []
  }),
  computed: {
    google: gmapApi
  },
  watch: {
    date: function() {
      this.dateFormat = this.$moment(this.date).format("dddd, MMMM Do, YYYY");
    }
  },
  methods: {
    prev: function() {
      this.date = this.$moment(this.date)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.show();
    },
    next: function() {
      this.date = this.$moment(this.date)
        .add(1, "day")
        .format("YYYY-MM-DD");
      this.show();
    },
    changeLetter: function(letter) {
      var este = this;
      var letra = este.routes[letter].route;
      console.log(letra);
      var datos = {};
      console.log(este.route);
      if (letter != este.activeRoute) {
        if (
          "routeschedule_id" in este.route &&
          este.route.routeschedule_id > 0
        ) {
          datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            routeschedule_id: este.route.routeschedule_id,
            newLetter: letra
          };
        } else {
          datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            currentDriver: este.route.route,
            routeschedule_id: 0,
            customer_id: este.route.id,
            pickUpDate: este.date,
            location: este.route.location,
            newLetter: letra,
            notes: este.route.notes,
            contactPersonAtClinic: este.route.contactPerson
          };
        }
        var laurl = "swapRoute";
        var mensaje = {};
        este.$http
          .post(este.$api + laurl, datos)
          .then(function(response) {
            if (response.status == 200 && response.data.status == "ok") {
              console.log("Swap Route Ready");
              window.console.log(response.status);
              mensaje = {
                color: "success",
                timeout: 5000,
                message: response.data.msg
              };
              este.$store.commit("msgMuestra", mensaje);
              este.activeRoute = letter;
              este.show();
            } else {
              mensaje = {
                color: "red",
                timeout: 5000,
                message: response.data.msg
              };
              este.$store.commit("msgMuestra", mensaje);
              if ("error" in response.data && response.data.error == "logout") {
                este.$router.push("/logout");
              }
            }
          })
          .catch(function(err) {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: "There was an error. Please try again."
            };
            este.$store.commit("msgMuestra", mensaje);
            window.console.log(err);
          });
      }
    },
    muestraRuta: function(index1, index2) {
      if (
        this.$moment(this.date).format("YYYY-MM-DD") >=
        this.$moment().format("YYYY-MM-DD")
      ) {
        console.log(this.date);
        console.log(index1, index2);
        this.activeRoute = index1;
        this.route = this.routes[index1].customers[index2];
        this.routeIndex = index2;
        console.log(this.route);
        this.markers = [
          {
            position: {
              lat: parseFloat(this.route.location[0].lat),
              lng: parseFloat(this.route.location[0].lng)
            },
            title: this.route.businessName
          }
        ];
        var este = this;
        este.points[index2] = {
          location:
            parseFloat(este.route.location[0].lat) +
            "," +
            parseFloat(este.route.location[0].lng),
          stopover: true
        };
        this.center = {
          lat: parseFloat(este.route.location[0].lat),
          lng: parseFloat(este.route.location[0].lng)
        };
        console.log(this.center);
        this.realStep = 1;
        if ("status" in this.route) {
          switch (this.route.status) {
            case "On Route":
              this.e1 = 2;
              break;
            case "Check In":
              this.e1 = 3;
              break;
            case "Extras / Notes":
              this.e1 = 4;
              break;
            case "Completed":
              this.e1 = 5;
              break;
            default:
              this.e1 = 1;
              break;
          }
        } else {
          this.e1 = 1;
        }
        this.dialog = true;
      }
    },
    show: function() {
      var este = this;
      este.$http
        .post(este.$api + "routes", {
          driver_id: este.$store.getters.driver_id,
          token: este.$store.getters.token,
          date: este.date,
          isManager: 1
        })
        .then(function(response) {
          if (response.status == 200 && response.data.status == "ok") {
            console.log("IMPRIME DATOS");
            window.console.log(response.status);
            if (!response.data.results) {
              este.message = response.data.msg;
              este.routes = [];
            } else {
              este.routes = response.data.data;
              este.id = response.data.id;
              este.optimizeRoute(
                response.data.data.filter(item => item.customers.length > 0)
              );
            }
          } else {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: response.data.msg
            };
            este.routes = [];
            este.$store.commit("msgMuestra", mensaje);
            if ("error" in response.data && response.data.error == "logout") {
              este.$router.push("/logout");
            }
          }
        })
        .catch(function(err) {
          este.routes = [];
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "There was an error. Please try again."
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    optimizeRoute: function(route) {
      var este = this;
      este.routes = route;
      setTimeout(function() {
        route.forEach(function(item, index) {
          console.log(item);
          este.initMap(item, index);
        });
      }, 100);
    },
    initMap: function(route, routeIndex) {
      var este = this;
      este.$refs.map.$mapPromise.then(map => {
        console.log(map);
        var google = this.google;
        //map = este.$refs.map.$mapObject;
        var directionsService = new google.maps.DirectionsService();
        var directionsDisplay = new google.maps.DirectionsRenderer();
        console.log(directionsDisplay);
        //directionsDisplay.setMap(map);
        var points = [];
        route.customers.forEach(function(item, index) {
          points[index] = {
            location: item.location[0].lat + "," + item.location[0].lng,
            stopover: true
          };
        });
        console.log(points);
        var optimizeWaypoints = true;
        if (route.manualorder > 0) {
          optimizeWaypoints = false;
        }
        console.log("PUNTOS");
        console.log(points);

        if (points.length > 1) {
          directionsService.route(
            {
              origin: "33.9190068,-118.1200275",
              destination: "33.9190068,-118.1200275",
              travelMode: "DRIVING",
              waypoints: points,
              optimizeWaypoints: optimizeWaypoints
            },
            function(response, status) {
              if (status === "OK") {
                // Acomoda la lista de la ruta
                if (optimizeWaypoints) {
                  response.routes[0].waypoint_order.forEach(function(it, ind) {
                    este.routes[routeIndex].customers[it].order = ind;
                  });
                  este.routes[routeIndex].customers.sort((a, b) =>
                    a.order > b.order ? 1 : -1
                  );
                } else {
                  este.routes[routeIndex].customers.sort((a, b) =>
                    a.order > b.order ? 1 : -1
                  );
                }
                // Pass data to the map
                //directionsDisplay.setDirections(response);

                // See the data in the console
                console.log(response);
              } else {
                var mensaje = {
                  color: "red",
                  timeout: 5000,
                  message: "Directions request failed due to " + status
                };
                este.$store.commit("msgMuestra", mensaje);
              }
            }
          );
        }
      });
    }
  },
  mounted: function() {
    this.date = this.$moment().format("YYYY-MM-DD");
    this.$store.commit("title", "Swap Routes");
    if (!this.$store.getters.token || !this.$store.getters.driver_id) {
      this.$router.push("/login");
    } else {
      this.show();
    }
  }
};
</script>
