<template>
  <div>
    <p v-if="error" class="error" v-html="error"></p>
    <h1>Manual Extras</h1>
    <p>Please add all extra containers being picked up.</p>
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="7" sm="7">
          <v-select :items="containers" item-text="container.container" item-value="container.id" label="Container"
            v-model="container"></v-select>
        </v-col>
        <v-col class="d-flex" cols="3" sm="3">
          <v-select :items="qtys" label="Qty" v-model="qty"></v-select>
        </v-col>
        <template v-if="container == 8 || container == 9">
          <v-col class="d-flex" col="10" sm="10">
            <v-select :items="paper_locations" label="Locations" v-model="paper_location"></v-select>
            <template v-if="paper_location == 'Other'">
              <v-text-field label="Other Location" v-model="other_location"></v-text-field>
            </template>
          </v-col>
        </template>
        <v-col class="d-flex" cols="2" sm="2">
          <v-btn depressed small color="success" @click="addContainers"
            :disabled="qty == '' || container == '' || (container == '8' && paper_location == '') || (container == '9' && paper_location == '') || (container == '8' && paper_location == 'Other' && other_location == '') || (container == '9' && paper_location == 'Other' && other_location == '')">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center mt-5" v-if="!result">
      <v-btn color="red" @click="extras" class="mx-auto white--text">
        Cancel
      </v-btn>
      <v-btn color="primary" @click="extras" class="mx-auto white--text ml-4">
        Continue
      </v-btn>
    </div>

    <p v-if="result" class="alert success" v-html="msg"></p>
    <div v-if="assigned.length > 0" class="mt-5">
      <h3>EXTRA CONTAINERS</h3>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Container</th>
              <th class="text-left">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in assigned" :key="index">
              <td>{{ item.container }} <span v-if="item.paper_location"> - {{ item.paper_location }}</span></td>
              <td>
                <v-btn color="red" @click="deleteContainer(index)" elevation="0" fab small dark
                  class="mx-auto white--text">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="text-center mt-5" v-if="result">
      <v-btn color="primary" @click="extras" class="mx-auto white--text mr-4">
        Continue
      </v-btn>
      <v-btn color="red" @click="extras" class="mx-auto white--text">
        Go Back
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      result: "",
      error: "",
      msg: "",
      assigned: [],
      containers: [],
      qtys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      qty: "",
      container: "",
      paper_location: "",
      paper_locations: [
        "Floor 1",
        "Floor 2",
        "Floor 3",
        "Coffee Room",
        "HR Office",
        "Front Desk",
        "Hallway",
        "Breakroom",
        "Supply Room",
        "Other",
      ],
      other_location: "",
    };
  },

  methods: {
    extras() {
      this.$router.push({
        name: "Routes",
        params: { route_back: this.$route.params.route_back }
      });
    },
    funcContinue() {
      this.$router.push({
        name: "Routes",
        params: { route_back: 4 }
      });
    },
    reset() {
      this.result = "";
    },
    deleteContainer(id) {
      var este = this;
      this.$getLocation()
        .then(coordinates => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "unextraContainer";
          var qr_container = this.assigned[id].qrcontainer_id;
          var qrl_id = this.assigned[id].id;
          var datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            location: este.location,
            customer_id: este.$store.getters.customer_id,
            rs: este.$store.getters.route_id,
            qr_container: qr_container,
            qrl_id: qrl_id
          };
          este.assigned.splice(id, 1);
          este.$http
            .post(este.$api + laurl, datos)
            .then(function (response) {
              este.assigned.splice(id, 1);
              este.result = true;
              este.msg = response.data.msg;
              este.assigned = response.data.assigned;
            })
            .catch(function (err) {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: "There was an error. Please try again."
              };
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
            });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again."
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    addContainers() {
      var este = this;
      este
        .$getLocation()
        .then(coordinates => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "extraContainerManual";
          var datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            customer_id: este.$store.getters.customer_id,
            location: este.location,
            rs: este.$store.getters.route_id,
            qr_container: este.container,
            qty: este.qty
          };
          if (este.container == 8 || este.container == 9) {
            datos.paper_location = este.paper_location;
            if (este.paper_location == "Other") {
              datos.paper_location = este.other_location;
            }
          }
          este.$http.post(este.$api + laurl, datos).then(function (response) {
            if (response.status == 200 && response.data.status == "ok") {
              este.result = true;
              este.msg = response.data.msg;
              este.assigned = response.data.assigned;
              este.qty = "";
              este.container = "";
            } else {
              este.result = false;
              este.error = response.data.msg;
              este.assigned = response.data.assigned;
            }
          });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again."
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    getAssigned() {
      var este = this;
      var laurl = "getManualExtra";
      var datos = {
        driver_id: este.$store.getters.driver_id,
        token: este.$store.getters.token,
        customer_id: este.$store.getters.customer_id,
        rs: este.$store.getters.route_id
      };
      este.$http
        .post(este.$api + laurl, datos)
        .then(function (response) {
          este.assigned = response.data.assigned;
          este.containers = response.data.containers;
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "There was an error. Please try again."
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    }
  },
  mounted() {
    this.getAssigned();
  }
};
</script>

<style scoped>
.error {
  color: white;
  padding: 10px;
  text-align: center;
}

.success {
  font-weight: bold;
  color: white;
  padding: 10px;
  text-align: center;
}
</style>
